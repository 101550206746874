import React, { useState } from "react";
import {
  AppBar,
  Button,
  Tab,
  Tabs,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import AddBusinessRoundedIcon from "@mui/icons-material/AddBusinessRounded";
import DrawerComp from "./Drawer";
import { Link } from "react-router-dom";
const Header = () => {
  const [value, setValue] = useState();
  const theme = useTheme();
  console.log(theme);
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  console.log(isMatch);

  return (
    <React.Fragment>
      <AppBar sx={{ background: "#000000" }} className="header_padidng">
        <Toolbar>
          <div className="imag">
            <Link className="nav_brand" to="/">
              {" "}
              <img
                src={require("../img/Web5logo.png")}
                className="blackshow logo"
              />
              {/* <img
                src={require("../img/Web5logolight.png")}
                className="whiteshow logo"
              /> */}
            </Link>
          </div>
          {isMatch ? (
            <>
              <DrawerComp />
            </>
          ) : (
            <>
              <div className="nav_link_section mr-auto header_flex">
                <a href="/">Home</a>
                <li class="nav-item dropdown">
                  <a
                    class="nav-link dropdown-toggle"
                    href="#mainhome"
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Build
                  </a>
                  <div
                    class="dropdown-menu mega-menu"
                    aria-labelledby="navbarDropdown"
                  >
                    <div class="row">
                      <div class="col-sm-12">
                        <div className="section_menur">
                          <a
                            href="https://github.com/web5nexus"
                            rel={"noreferrer"}
                            target={"_blank"}
                          >
                            <div>
                              <i class="ri-tools-fill"></i>
                            </div>
                            <div className="sdk_list">
                              <p>SDK</p>
                              <small>Learn about our powerful SDK module</small>
                            </div>
                          </a>

                          <a
                            href="https://docs.web5.nexus"
                            rel={"noreferrer"}
                            target={"_blank"}
                          >
                            <div>
                              <i class="ri-file-list-2-line"></i>
                            </div>
                            <div className="sdk_list">
                              <p>Documentation</p>
                              <small>
                                Read the official guides & start integrating
                                today
                              </small>
                            </div>
                          </a>
                          <a href="https://dashboard.web5.nexus">
                            <div>
                              <i class="ri-dashboard-line"></i>
                            </div>
                            <div className="sdk_list">
                              <p>Dashboard</p>
                              <small>
                                Register your project & make it seamless
                              </small>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="nav-item dropdown">
                  <a
                    class="nav-link dropdown-toggle"
                    href="#mainhome"
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Product
                  </a>
                  <div
                    class="dropdown-menu mega-menu"
                    aria-labelledby="navbarDropdown"
                  >
                    <div class="row">
                      <div class="col-sm-12">
                        <div className="section_menur pl-3">
                        <a href="https://www.npmjs.com/package/@web5nexus/sociallogin">
                            <div className="sdk_list">
                              <p>Social Login</p>
                              <small>Integrate our Social Auth to your platform</small>
                            </div>
                          </a>
                          <a href="/crossdex">
                            <div className="sdk_list">
                              <p>Cross DEX</p>
                              <small>Learn about our Cross DEX platform</small>
                            </div>
                          </a>
                          <a href="/onofframp">
                            <div className="sdk_list">
                              <p>On/Off ramp solutions</p>
                              <small>
                                Learn about our On/Off ramp solutions
                              </small>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <a href="">Solution</a>
                <a href="mailto:connect@web5.nexus">Contact Us</a>
              </div>

              <a
                href="https://deck.web5.nexus/"
                rel={"noreferrer"}
                target={"_blank"}
                className="Butn_header mr-0"
              >
                Product Deck
              </a>
            </>
          )}
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default Header;
