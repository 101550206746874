import React from "react";
import Header from "./HeaderNew";
import ButtonTrax from "./Buttontrak";
import SyntaxHighlighter from "react-syntax-highlighter";
import Button from "@mui/material/Button";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Token from "./Componant/Token";
import { dark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import data from "./blockchain.json"


function NewHome() {
  const settings = {
    dots: false,
    arrows: true, // Hide the dots navigation
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };
  const codeString = ` import Web5Nexus from "@web5nexus/web3auth-core";

    const clientId = "Your Web3Auth Client ID"
    
    // Define an async function to wrap the code
    async function runWeb5() {
      // Initialize Web5 Nexus
      const web5 = new Web5Nexus.Web5(clientId, "MAINNET");
      const blockchain = "xinfin";
      await web5.init(blockchain);
  
      // Call the Google Auth
      await web5.google();
    }
  
    // Call the async function
    runWeb5().catch((error) => {
      console.error("An error occurred:", error);
    });
    `;

  const codeString1 = ` import Web5Nexus, { Web3AuthNoModal } from "@web5nexus/web3auth-core";
  
    const clientId = "Your Web3Auth Client ID"
  
    function App() {
      const [web3auth, setWeb3auth] = useState<Web3AuthNoModal | null>(null);
      const [provider, setProvider] = useState<any | null>(null);
      
      // Initialize Web5
      const web5 = new Web5Nexus.Web5(clientId, "MAINNET");
  
      useEffect(() => {
  
        const initialize = async () => {
          try {
            const blockchain = "xinfin";
            await web5.init(blockchain);
          } catch (error) {
            console.error(error);
          }
        }
        initialize();
    
      }, []);
  
      const loginGoogle = async () => {
        const web3authProvider = await web5.google()
        setProvider(web3authProvider);
        setWeb3auth(web5.web3auth);
      };
  
      return (
        // Your UI Here
      )
    }
    export default App;`;

  const codeString2 = ` import { Web5 } from "@web5nexus/web3auth-core";
  
    const clientId: string = "Your Web3Auth Client ID";
    
    // Define an async function to wrap the code
    async function runWeb5(): Promise<void> {
      // Initialize Web5 Nexus
      const web5: Web5 = new Web5(clientId, "MAINNET");
      const blockchain: string = "xinfin";
      await web5.init(blockchain);
    
      // Call the Google Auth
      await web5.google();
    }
    
    // Call the async function
    runWeb5().catch((error: Error) => {
      console.error("An error occurred:", error);
    });`;

  return (
    <>
      <Header />
      <main className="main_padding">
        <section className="banner_new new_product_banner">
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <div className="product_banner ">
                  <h1>
                    Experience a Secure <span>Cross DEX </span> Exchange.{" "}
                  </h1>
                  <p>
                    Welcome to the world of effortless cryptocurrency trading!
                    At Web5 Nexus, we are proud to introduce you to our
                    state-of-the-art Cross Decentralized Exchange (Cross Dex).
                    Here, we have seamlessly combined user-friendliness and
                    cutting-edge technology to create a trading platform that's
                    second to none.
                  </p>
                  <div className="button_section">
                    <a
                      href="https://crossdex.web5.nexus/#/"
                      target="_blank"
                      className="outline"
                    >
                      Explore
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section_2  section_4 section__produce">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="iamge_">
                  <img
                    src={require("../img/aboutDEX.png")}
                    alt="logo"
                    className="img-fluid Swap"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="contet_2 contet_3">
                  <h2>
                    About
                    <br />
                    <span>Web5Nexus</span>
                    <br /> Cross Dex ?
                  </h2>
                  <p>
                    Cross Dex, short for Cross Decentralized Exchange, is the
                    future of cryptocurrency trading. Unlike traditional
                    exchanges, it lets you trade cryptocurrencies across
                    different blockchains, offering unparalleled flexibility.
                    Using advanced technologies like atomic swaps and bridges,
                    Cross Dex ensures secure, decentralized trading with
                    enhanced liquidity. Experience the future of crypto trading
                    today!"
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section_2  section_4 section__produce bg_final">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="contet_2 contet_3 brinde_nexsu">
                  <h2>
                    <span>Web5 Nexus Bridge</span>
                    <br />
                    Bridging the Gap in Blockchain Connectivity
                  </h2>
                  <p>
                    Unlock a new era of blockchain innovation with the Web5
                    Nexus Bridge. Seamlessly connect blockchain networks,
                    facilitate cross-chain transactions, and empower DeFi
                    platforms and DApps to harness assets from multiple
                    blockchains. Our platform's core is fortified with robust
                    security measures, ensuring trustless asset transfers, while
                    real-world data integration and oracle support enrich your
                    blockchain applications. Elevate your entire blockchain
                    ecosystem and embark on a journey towards a more
                    interconnected and accessible blockchain landscape. Embrace
                    the revolution – choose Web5 Nexus Bridge.
                  </p>
                  <Button>Explore Now</Button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section_2 section_3 Section_secure_card Section_secure_card2">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 ">
                <div className="contet_2 contet_3">
                  <h2 className="text-center secodary_h2">
                    <span className="secodary_span">key features</span> <br />
                    of our Cross Decentralized Exchange
                  </h2>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-4 d-flex">
                <div className="card_secure">
                  <div className="img_secur">
                    <img
                      src={require("../img/producticons/money-transfer.png")}
                      className="img-fluid"
                    />
                  </div>
                  <div>
                    <h3>Lightning-Fast Transactions</h3>
                    <p>
                      Experience rapid transaction processing for quick and
                      efficient trading.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 d-flex">
                <div className="card_secure">
                  <div className="img_secur">
                    <img
                      src={require("../img/producticons/expand.png")}
                      className="img-fluid"
                    />
                  </div>
                  <div>
                    <h3>Expanded Cryptocurrency Options</h3>
                    <p>
                      Access a wide variety of cryptocurrencies, providing you
                      with a diverse portfolio of digital assets to trade.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 d-flex">
                <div className="card_secure">
                  <div className="img_secur">
                    <img
                      src={require("../img/producticons/security.png")}
                      className="img-fluid"
                    />
                  </div>
                  <div>
                    <h3>Enhanced Security Features</h3>
                    <p>
                      Benefit from advanced security measures to safeguard your
                      investments and protect your data.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 d-flex">
                <div className="card_secure">
                  <div className="img_secur">
                    <img
                      src={require("../img/producticons/swap.png")}
                      className="img-fluid"
                    />
                  </div>
                  <div>
                    <h3>Effortless Cross-Chain Swaps</h3>
                    <p>
                      Easily exchange cryptocurrencies across different
                      blockchain networks, offering flexibility and convenience
                      in your trading activities.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 d-flex">
                <div className="card_secure">
                  <div className="img_secur">
                    <img
                      src={require("../img/producticons/currency.png")}
                      className="img-fluid"
                    />
                  </div>
                  <div>
                    <h3>Best Exchange Rate</h3>
                    <p>
                      Enjoy competitive and favorable exchange rates for your
                      cryptocurrency trades.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 d-flex">
                <div className="card_secure">
                  <div className="img_secur">
                    <img
                      src={require("../img/producticons/infinity.png")}
                      className="img-fluid"
                    />
                  </div>
                  <div>
                    <h3>Unlimited Cross-Chain Swaps</h3>
                    <p>
                      Seamlessly swap cryptocurrencies across various blockchain
                      networks without limitations.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 d-flex">
                <div className="card_secure">
                  <div className="img_secur">
                    <img
                      src={require("../img/producticons/centralized.png")}
                      className="img-fluid"
                    />
                  </div>
                  <div>
                    <h3>Decentralized and No KYC</h3>
                    <p>
                      Experience true decentralization with no Know Your
                      Customer (KYC) requirements, ensuring privacy and freedom
                      in your trading activities.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="product_new_sectoion">
          <div className="container-fluid">
            <div className="card_product_diplay">
              <div className="row">
                <div className="col-lg-12 ">
                  <div className="contet_2 contet_3">
                    <h2 className="text-center secodary_h2">
                      <span className="secodary_span">Access and </span> <br />
                      Witness the excellence of our exchange.
                    </h2>
                    {/* <p>The Most Secure and Reliable Cross DEX Exchange – Simplifying Your Trades</p> */}
                  </div>
                </div>
                <div className="col-lg-12"></div>
                <div className="col-lg-12">
                  {/* <div className='mac_over'>
  <div className='slider_box'>

  </div>
<img  src={require('../img/samble_mac.png')} alt='logo' className='img-fluid' />
</div> */}
                  <div class="mbp-mockup-wrapper">
                    <div class="mbp-container">
                      <div class="mbp-display with-glare">
                        <div class="display-edge">
                          <div class="bezel">
                            <div class="display-camera"></div>
                            <div class="display-frame">
                              <iframe
                                src="https://crossdex.web5.nexus/"
                                allowfullscreen
                              ></iframe>
                            </div>
                            <div class="below-display">
                              <div class="macbookpro"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="mbp-keyboard">
                        <div class="front">
                          <div class="opener-left"></div>
                          <div class="opener-right"></div>
                        </div>
                        <div class="bottom-left"></div>
                        <div class="bottom-right"></div>
                        <div class="mbp-shadow">
                          <div class="shadow-left"></div>
                          <div class="shadow-right"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section_2 section_3 Section_secure_card">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 ">
                <div className="contet_2 contet_3">
                  <h2 className="secodary_h2">
                    High-Level Security and Regulatory Compliance for
                    Enterprises
                  </h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 d-flex">
                <div className="card_secure">
                  <div>
                    <h3>Non-custodial Infrastructure</h3>
                    <p>
                      True decentralization and ownership with
                      Wallet-as-a-service (WaaS) when it comes to all digital
                      assets.
                    </p>
                  </div>
                  <div className="img_secur">
                    <img
                      src={require("../img/producticons/infrastructure.png")}
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-4 d-flex">
                <div className="card_secure">
                  <div>
                    <h3>GDPR Compliance</h3>
                    <p>
                      Privacy of your users matter. Designed to be compliant
                      from ground up.
                    </p>
                  </div>
                  <div className="img_secur">
                    <img
                      src={require("../img/producticons/compliance.png")}
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-4 d-flex">
                <div className="card_secure">
                  <div>
                    <h3>Regularly Audited Codebase</h3>
                    <p>
                      Both internally and externally audited regularly by the
                      best.
                    </p>
                  </div>
                  <div className="img_secur">
                    <img
                      src={require("../img/producticons/search.png")}
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="usecases sectio_pading bg_hight_sectio product_support">
          <div className="container">
            <div className="contet_2 contet_3 title_center_flex">
              <h2 className="text-center secodary_h2">
                <span className="secodary_span">Supported</span>
                <br />
                Blockchain Network
              </h2>
            </div>
            <div className="row mt-5 justify-content-center">
              <div style={{ color: 'white' }} className="row mt-5 justify-content-center">
                {data.map((screenshot, index) => (
                  <div className="col-lg-1 col-md-2 col-sm-3">
                    <div className="finance-card shadow">
                      <div className="card-img-fiance">
                        <img src={`https://transfer.swft.pro/swft-v3/images/coins/${screenshot.chain}.png`} alt={screenshot.name} className="img-fluid" />
                      </div>

                    </div>
                    <div className="featuretext mt-3 justify-content-center">
                      <h5 style={{ textAlign: 'center' }} className="card-title">{screenshot.name}</h5>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
        <section className="usecases sectio_pading bg_hight_sectio product_support">
          <div className="container">
            <div className="contet_2 contet_3 title_center_flex">
              <h2 className="text-center secodary_h2">
                <span className="secodary_span">Supported</span>
                <br />
                Tokens
              </h2>
            </div>

            <div className="height_contain">
              <Token />
            </div>
          </div>
        </section>

        <section className="CTA">
          <div className="contanier">
            <div className="row justify-content-center">
              <div className="col-lg-9">
                <div className="CTAcontent">
                  <h2>
                    Enjoy Even Lower Fees as a Token of Our Appreciation for Our
                    Loyal Supporters.
                  </h2>
                  <a href="mailto:connect@web5.nexus">
                    <Button>Swap Now</Button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <footer>
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="text_footer">
                  <div className="logo_section">
                    <a class="navbar-brand light" href="https://web5.nexus/">
                      <img
                        src={require("../img/Web5logo.png")}
                        alt="logo"
                        className="logo"
                      />
                    </a>
                  </div>
                  <div className="socila_link">
                    <ul>
                      {/* <li><a href='' rel={'noreferrer'} target='_blank'><i class="ri-facebook-fill"></i></a></li>
                      <li><a href='' rel={'noreferrer'} target='_blank'><i class="ri-twitter-fill"></i></a></li>
                      <li><a href='' rel={'noreferrer'} target='_blank'><i class="ri-telegram-fill"></i></a></li>
                      <li><a href='' rel={'noreferrer'} target='_blank'><i class="ri-whatsapp-fill"></i></a></li> */}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="row">
                  <div className="col-lg-4">
                    <div className="link_date">
                      <h6>Products</h6>
                      <ul>
                        <li>
                          <a href="https://docs.web5.nexus/">Wallet</a>
                        </li>
                        <li>
                          <a href="https://docs.web5.nexus/">Market</a>
                        </li>
                        <li>
                          <a href="https://docs.web5.nexus/">NFT Tools</a>
                        </li>
                        <li>
                          <a href="https://docs.web5.nexus/">Game toolkit</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="link_date">
                      <h6>Developers</h6>
                      <ul>
                        <li>
                          <a href="https://docs.web5.nexus/">Documentation</a>
                        </li>
                        <li>
                          <a href="mailto:connect@web5.nexus">Support</a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="col-lg-4">
                    <div className="link_date">
                      <h6>Company</h6>
                      <ul>
                        <li>
                          <a href="#about">About us</a>
                        </li>
                        {/* <li><a href=''>Careers</a></li>
                        <li><a href=''>Blog</a></li>
                        <li><a href=''>Podcast</a></li> */}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-lg-12">
                <ul className="nexcud">
                  <li>
                    <a href="https://web5.nexus/">Privacy Policy</a>
                  </li>
                  <li>
                    <a href="https://web5.nexus/">Terms of Service</a>
                  </li>
                  <li>
                    <a href="https://web5.nexus/">Cookies Policy</a>
                  </li>
                  <li>
                    <a href="https://web5.nexus/">Security</a>
                  </li>
                </ul>
                <div className="copy_right">
                  <p>© 2023 Web5 Nexus. All right reserved.</p>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </main>
    </>
  );
}

export default NewHome;
