import React, { useState } from 'react';
// import './CardWithMouseTracker.css'; // Import your CSS file

function CardWithMouseTracker() {
  const [circlePosition, setCirclePosition] = useState({ x: 0, y: 0 });

  const handleMouseMove = (e) => {
    // Calculate the position of the mouse cursor relative to the card
    const xPos = e.nativeEvent.offsetX;
    const yPos = e.nativeEvent.offsetY;

    // Update the position of the gradient circle
    setCirclePosition({ x: xPos, y: yPos });
  };

  return (
    <div className="card1" onMouseMove={handleMouseMove}>
      <div
        className="circle"
        style={{
          left: `${circlePosition.x}px`,
          top: `${circlePosition.y}px`
        }}
      ></div>
      <h1>Card with Gradient Circle</h1>
      <p>Hover over this card to see a gradient circle that follows your mouse!</p>
    </div>
  );
}

export default CardWithMouseTracker;
