import React, { Component } from 'react';
import $ from 'jquery'; // Make sure you have jQuery installed

class CoinList extends Component {
  componentDidMount() {
    const data = {
      equipmentNo: "guanwang123456789012345678901234",
    };

    $.ajax({
      url: "https://www.swftc.info/api/v1/queryAllCoinList",
      type: "POST",
      data: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json;",
      },
      dataType: 'json',
      success: (response) => {
        const resultList = response.data;
        const html = resultList.map((item, index) => (
          <div className='col-lg-3'>
<div className="item logos_logo-wrapper" key={index}>
            <img src={`https://swap.swftcoin.com/swft-v3/images/coins/${item.coinCode}.png`} alt="" />
            <div className="coin-name">{item.coinCode}</div>
          </div>
          </div>
        ));
        this.setState({ html });
      },
      error: () => {
        alert("error");
      },
    });
  }

  constructor(props) {
    super(props);
    this.state = {
      html: [],
    };
  }

  render() {
    return (
      <div className='row container_token_section'>
        {this.state.html}
      </div>
    );
  }
}

export default CoinList;
