import React from 'react'
import Header from './HeaderNew'
// import ButtonTrax from "./Buttontrak"
import SyntaxHighlighter from 'react-syntax-highlighter';
import Button from '@mui/material/Button';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { dark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import data from "./blockchain.json"

function NewHome() {
  const settings = {
    dots: false,
    arrows: true,              // Hide the dots navigation
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000
  };
  const codeString = ` 
  
  import SocialLogin,{EvmRpc} from "@web5nexus/sociallogin";
  import {BlockchainType,NetworkOption,Web3AuthParamsType} from "@web5nexus/coretypes";

  // Pre-Requiste
  // Get from https://dashboard.web3auth.io for Saphire Mainnet only
  const clientId = "Client ID recieved from Web3Auth Dashboard";
  const clientSecret = "Client Secret Recieved from Web3Auth Dashboard";
  
  // Addional Customization
  const name = "XDC Auth"
  const logo = "https://xinfin.org/assets/images/brand-assets/xdc-icon.png"
  const network :NetworkOption = "sapphire_mainnet";
  
  const whiteLabel = {
      name :name,
      logo :logo
  }
  const params: Web3AuthParamsType={
      type: "web3auth",
      clientId:clientId,
      clientSecret:clientSecret
  }
  
  // init wallet
  const socialLoginSDK = new SocialLogin(params,whiteLabel);
  await socialLoginSDK.init(network);
  // show connect modal
  socialLoginSDK.showWallet();
  
  
  // For Blockchain Instance 
  const blockchain:BlockchainType ={
      blockchain:"xinfin",
      network:"mainnet"
  }
  
  // Optional own RPC URL 
  var rpcUrl = "https://erpc.xdcrpc.com";
  const xdcInstance = new EvmRpc(socialLoginSDK.provider,blockchain,rpcUrl)
    `;

  const codeString1 = ` 
  import React, { useEffect } from 'react';
import SocialLogin, { EvmRpc } from '@web5nexus/sociallogin';
import { BlockchainType, NetworkOption, Web3AuthParamsType } from '@web5nexus/coretypes';

function YourComponent() {
  // Pre-Requisite: Get the client ID and client Secret from https://dashboard.web3auth.io (Saphire Mainnet only)
  const clientId = 'YourClientID'; // Replace with your client ID
  const clientSecret = 'YourClientSecret'; // Replace with your client secret

  // Additional Customization
  const name = 'XDC Auth';
  const logo = 'https://xinfin.org/assets/images/brand-assets/xdc-icon.png';
  const network: NetworkOption = 'sapphire_mainnet';

  const whiteLabel = {
    name: name,
    logo: logo,
  };

  const params: Web3AuthParamsType = {
    type: 'web3auth',
    clientId: clientId,
    clientSecret: clientSecret,
  };

  // Initialize wallet
  useEffect(() => {
    async function initializeWallet() {
      const socialLoginSDK = new SocialLogin(params, whiteLabel);
      await socialLoginSDK.init(network);
      socialLoginSDK.showWallet();

      // For Blockchain Instance
      const blockchain: BlockchainType = {
        blockchain: 'xinfin',
        network: 'mainnet',
      };

      // Optional custom RPC URL
      var rpcUrl = 'https://erpc.xdcrpc.com';
      const xdcInstance = new EvmRpc(socialLoginSDK.provider, blockchain, rpcUrl);

      // Use xdcInstance for interacting with XinFin XDC blockchain
    }

    initializeWallet();
  }, []);

  return (
    <div>
      {/* Your React component content */}
    </div>
  );
}

export default YourComponent;

  
  `;

  const codeString2 = ` 
  
  import React, { useEffect } from 'react';
import SocialLogin, { EvmRpc } from '@web5nexus/sociallogin';
import { BlockchainType, NetworkOption, Web3AuthParamsType } from '@web5nexus/coretypes';

function YourComponent() {
  // Pre-Requisite: Get the client ID and client Secret from https://dashboard.web3auth.io (Sapphire Mainnet only)
  const clientId = 'YourClientID'; // Replace with your client ID
  const clientSecret = 'YourClientSecret'; // Replace with your client secret

  // Additional Customization
  const name = 'XDC Auth';
  const logo = 'https://xinfin.org/assets/images/brand-assets/xdc-icon.png';
  const network: NetworkOption = 'sapphire_mainnet';

  const whiteLabel = {
    name: name,
    logo: logo,
  };

  const params: Web3AuthParamsType = {
    type: 'web3auth',
    clientId: clientId,
    clientSecret: clientSecret,
  };

  // Initialize wallet
  useEffect(() => {
    async function initializeWallet() {
      const socialLoginSDK = new SocialLogin(params, whiteLabel);
      await socialLoginSDK.init(network);
      socialLoginSDK.showWallet();

      // For Blockchain Instance
      const blockchain: BlockchainType = {
        blockchain: 'xinfin',
        network: 'mainnet',
      };

      // Optional custom RPC URL
      var rpcUrl = 'https://erpc.xdcrpc.com';
      const xdcInstance = new EvmRpc(socialLoginSDK.provider, blockchain, rpcUrl);

      // Use xdcInstance for interacting with XinFin XDC blockchain
    }

    initializeWallet();
  }, []);

  return (
    <div>
      {/* Your React component content */}
    </div>
  );
}

export default YourComponent;

  
  `;

  return (
    <>
      <Header />
      <main className='main_padding'>




        <section className='banner_new'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <div className='main_banner_content'>
                  <h1>Web3 Simplified</h1>
                  <p>One Platform, Endless Possibilities for Seamless Integration & User Retention

                  </p>
                  <a href='https://docs.web5.nexus' rel={'noreferrer'} target={'_blank'}>Explore the SDK</a>

                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='section_2'>
          <div className='container'>
            <div className='row justify-content-center d-flex'>
              <div className='col-lg-12'>
                <div className='contet_2'>
                  <h2 className='text-center'>Experience the magic of seamless Web2 to Web3 onboarding and effortless user experience</h2>
                </div>
              </div>
              <div className='col-lg-5'>
              </div>
            </div>

          </div>
        </section>
        <section className='section_2 section_3'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-8'>
                <div className='contet_2 contet_3'>
                  <h2>Go from complex, multi-step interactions to one-click experiences</h2>
                  <p>Easily batch multiple transactions, turning complicated user journeys on your dApp into a few clicks.</p>
                  <a href='https://calendly.com/blockchain/interaction' rel={'noreferrer'} target={'_blank'}>Book Demo</a>
                </div>
              </div>
              <div className='col-lg-4'>
              </div>
            </div>

          </div>
        </section>
        <section className='section_2  section_4'>
          <div className='container'>
            <div className='row'>

              <div className='col-lg-12'>
                <div className='contet_2 contet_3 align-items-center'>
                  <h2 className='text-center'>What Is Web5 Nexus</h2>
                  <p className='text-center'>Web5 Nexus is a comprehensive and versatile web3 integration platform that simplifies the onboarding process for developers, regardless of their expertise in blockchain technology. It offers a range of solutions such as SDKs, APIs, plug-and-play widgets, and a unified place to access various development tools, reducing the need for multiple sources and billing. Web5 Nexus is chain-agnostic, meaning it provides a layer on top of existing services, offering support for multiple blockchains, making it easier for users to onboard onto different blockchains. In essence, it's a one-stop-shop for developers looking to integrate blockchain solutions into their applications, streamlining the development process and facilitating web3 integration.</p>
                </div>
              </div>

            </div>

          </div>
        </section>
        <div className='large_section_img'>
          <section className='section_2  section_4 section_4_non'>
            <div className='container'>
              <div className='row'>
                <div className='col-lg-12'>
                  <div className='contet_2 contet_3 title_center_flex  pb-4'>
                    <h2 className='text-center'>In All Language</h2>
                  </div>
                  <div className='Tabsa_all'>
                    <div className='row justify-content-center'>
                      <div className='col-lg-9'>
                        <div className='code_section'>
                          <ul class="nav nav-tabs mb-4">
                            <li class="active"><a data-toggle="tab" href="#home" className='active'> <img alt="" src={require('../img/javascript-logo-svgrepo-com.png')} className='image_ofo' />javascript</a></li>
                            <li><a data-toggle="tab" href="#menu1"><img alt="" src={require('../img/react-svgrepo-com.png')} className='image_ofo' /> React</a></li>
                            <li><a data-toggle="tab" href="#menu2"><img alt="" src={require('../img/typescript.png')} className='image_ofo' /> TypeScript
                            </a></li>
                          </ul>
                          <div>
                            <div class="tab-content">
                              <div id="home" class="tab-pane fade in active show">
                                <SyntaxHighlighter language="javascript" style={dark} customStyle={{ padding: "25px" }} wrapLongLines={true}>
                                  {codeString}
                                </SyntaxHighlighter>
                              </div>
                              <div id="menu1" class="tab-pane fade">
                                <SyntaxHighlighter language="javascript" style={dark} customStyle={{ padding: "25px" }} wrapLongLines={true}>
                                  {codeString1}
                                </SyntaxHighlighter>
                              </div>
                              <div id="menu2" class="tab-pane fade">
                                <SyntaxHighlighter language="javascript" style={dark} customStyle={{ padding: "25px" }} wrapLongLines={true}>
                                  {codeString2}
                                </SyntaxHighlighter>
                              </div>
                            </div>
                            <div className='copy_icons'><Button><i class="ri-file-copy-line"></i></Button></div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


              </div>

            </div>
          </section>
          <section className='section_2  section_4 section_4_non bg_new screen_shoot_p title_center_flex' >
            <div className='container'>
              <div className='row'>
                <div className='col-lg-12'>
                  <div className='contet_2 contet_3 '>
                    <h2 className='text-center'>All the tools your Web3 space needs in one service</h2>
                  </div>
                  <div className='screen_shot'>
                    <ul class="nav nav-tabs mb-4">
                      <li class="active"><a data-toggle="tab" href="#Wallet" className='active'>Social Wallet</a></li>
                      <li ><a data-toggle="tab" href="#Dashboard" > Dashboard </a></li>
                    </ul>
                  </div>
                </div>

                <div className='col-lg-12'>
                  <div class="tab-content ">
                    <div id="Dashboard" class="tab-pane fade">
                      <Slider {...settings}>
                        
                        <div>
                          <img alt="" src={require('../img/admin/home.png')} className='img-fluid' />
                        </div>
                        <div>
                          <img alt="" src={require('../img/admin/plug_and_play.png')} className='img-fluid' />
                        </div>
                        <div>
                          <img alt="" src={require('../img/admin/plug_and_play_2.png')} className='img-fluid' />
                        </div>
                        <div>
                          <img alt="" src={require('../img/admin/payment_&_subscription.png')} className='img-fluid' />
                        </div>
                        <div>
                          <img alt="" src={require('../img/admin/payment.png')} className='img-fluid' />
                        </div>
                        <div>
                          <img alt="" src={require('../img/admin/branding.png')} className='img-fluid' />
                        </div>
                        <div>
                          <img alt="" src={require('../img/admin/wallet.png')} className='img-fluid' />
                        </div>
                      </Slider>
                    </div>
                    <div id="Wallet" class="tab-pane fade in active show">
                      <Slider {...settings}>
                        <div>
                          <img alt="" src={require('../img/admin/wallet_mbile_11.png')} className='img-fluid wlallet_imf' />
                        </div>
                        <div>
                          <img alt="" src={require('../img/admin/wallet_mobile_1.png')} className='img-fluid wlallet_imf' />
                        </div>
                        <div>
                          <img alt="" src={require('../img/admin/wallet_mbile_2.png')} className='img-fluid wlallet_imf' />
                        </div>
                        <div>
                          <img alt="" src={require('../img/admin/wallet_mobile_3.png')} className='img-fluid wlallet_imf' />
                        </div>
                        <div>
                          <img alt="" src={require('../img/admin/wallet_mobile.png')} className='img-fluid wlallet_imf' />
                        </div>

                      </Slider>
                    </div>

                  </div>

                </div>
              </div>


            </div>
          </section>
        </div>
        <section id="solutions" className='usecases  pb-0 solutions_card pt-5'>
          <div className='container'>
            <div className='contet_2 contet_3 title_center_flex'>
              <h2 className='text-center'>Start building <br />with Web5 Nexus tools</h2>
            </div>

            <div className='row mt-4'>
              <div className='col-lg-4' >
                <div className='uses_card' data-scroll="" data-scroll-speed="1" data-scroll-direction="top">
                  <div className='lable_sect'> <i class="ri-paint-fill"></i>Web3 Social Auth</div>
                  <h4>Non Custodial Social Wallet</h4>
                  <p>This feature enables developers to implement decentralized social authentication across various EVM (Ethereum Virtual Machine) and non-EVM blockchains. It allows users to authenticate and access applications securely using their decentralized identities, eliminating the need for centralized authentication providers.</p>
                  <a href='https://www.npmjs.com/package/@web5nexus/sociallogin' target='blank'><Button>Explore Social Wallet</Button></a>
                </div>
              </div>
              <div className='col-lg-4' >
                <div className='uses_card' data-scroll="" data-scroll-speed="1" data-scroll-direction="top">
                  <div className='lable_sect'> <i class="ri-paint-fill"></i> Cross Swap DEX Aggregator </div>
                  <h4>Cross Swap Dex Widget</h4>
                  <p>Web5 Nexus Cross Swap DEX is a decentralized exchange that allows users to trade cryptocurrencies across different blockchain networks seamlessly. It enables you to swap digital assets from one blockchain to another without the need for intermediaries. This cross-chain compatibility expands the reach and flexibility of your crypto trading activities, providing access to a wider range of assets and networks.</p>
                  <a href='/crossdex'><Button>Explore Cross Dex</Button></a>
                </div>
              </div>
              <div className='col-lg-4' >
                <div className='uses_card' data-scroll="" data-scroll-speed="1" data-scroll-direction="top">
                  <div className='lable_sect'> <i class="ri-paint-fill"></i> Fiat On/Off Ramp - INR & Other </div>
                  <h4>Fiat On/Off Ramp Widget</h4>
                  <p>The Fiat On/Off Ramp Widget simplifies the process of buying and selling cryptocurrencies using fiat currencies. It offers a user-friendly interface that allows users to seamlessly convert between fiat and crypto assets within the application.</p>
                  <a href='/onofframp'><Button>Explore On/Off Ramp</Button></a>
                </div>
              </div>
              <div className='col-lg-4' >
                <div className='uses_card' data-scroll="" data-scroll-speed="1" data-scroll-direction="top">
                  <div className='lable_sect'> <i class="ri-paint-fill"></i>Decentralized Wallet Auth</div>
                  <h4>Decentralized Wallets Connection</h4>
                  <p>Web5 Nexus provides seamless integration with a wide range of wallets available in the web3 space for both EVM and non-EVM blockchains. Developers can easily connect their applications with these wallets, allowing users to manage their digital assets and interact with decentralized applications.</p>
                  <Button>Coming Soon</Button>
                </div>
              </div>
              <div className='col-lg-4' >
                <div className='uses_card' data-scroll="" data-scroll-speed="1" data-scroll-direction="top">
                  <div className='lable_sect'> <i class="ri-paint-fill"></i>Multichain Wallet</div>
                  <h4>Blockchain Wallets API & SDK</h4>
                  <p>With the Blockchain Wallets and Transaction API, developers can generate wallets programmatically and initiate transactions through API calls. This feature streamlines the process of wallet creation and transaction management, providing a convenient way to interact with the blockchain.</p>
                  <Button>Coming Soon</Button>
                </div>
              </div>

              <div className='col-lg-4' >
                <div className='uses_card' data-scroll="" data-scroll-speed="1" data-scroll-direction="top">
                  <div className='lable_sect'> <i class="ri-paint-fill"></i> Web3 Marketing </div>
                  <h4>Notification Service</h4>
                  <p>Web5 Nexus provides a notification service that allows developers to create subscriptions, monitor specific addresses, and receive real-time notifications for blockchain events. This feature enables developers to build applications that can react to changes on the blockchain instantly.</p>
                  <Button>Coming Soon</Button>
                </div>
              </div>

              <div className='col-lg-4' >
                <div className='uses_card' data-scroll="" data-scroll-speed="1" data-scroll-direction="top">
                  <div className='lable_sect'> <i class="ri-paint-fill"></i> CEX Trade Bundle </div>
                  <h4>CEX Trade API & SDK</h4>
                  <p>This feature allows users to buy and sell cryptocurrencies on centralized exchanges (CEX) using a custom SDK and API. It provides developers with the tools to incorporate CEX trading functionality within their applications, expanding the options for users to interact with different trading platforms</p>
                  <Button>Coming Soon</Button>
                </div>
              </div>
              <div className='col-lg-4' >
                <div className='uses_card' data-scroll="" data-scroll-speed="1" data-scroll-direction="top">
                  <div className='lable_sect'> <i class="ri-paint-fill"></i> DEX Trade Bundle </div>
                  <h4>DEX Trade API & SDK</h4>
                  <p>This feature allows users to buy and sell cryptocurrencies on decentralized exchanges (DEX) like pancake, uniswap, quickswap etc using a custom SDK and API. It provides developers with the tools to incorporate DEX trading functionality within their applications, expanding the options for users to interact with different DEX platforms</p>
                  <Button>Coming Soon</Button>
                </div>
              </div>
              <div className='col-lg-4' >
                <div className='uses_card' data-scroll="" data-scroll-speed="1" data-scroll-direction="top">
                  <div className='lable_sect'> <i class="ri-paint-fill"></i> Tradeview Custom Widget  </div>
                  <h4>Graph Widget</h4>
                  <p>The Graph Widget provides a trade graph component specifically designed for cryptocurrencies on React. It offers visual representations of market trends and trading volumes, empowering users to make informed decisions when trading or monitoring cryptocurrency prices.</p>
                  <br />
                  <Button>Coming Soon</Button>
                </div>
              </div>
              <div className='col-lg-4' >
                <div className='uses_card' data-scroll="" data-scroll-speed="1" data-scroll-direction="top">
                  <div className='lable_sect'> <i class="ri-paint-fill"></i> Decentralized Database  </div>
                  <h4>Database API & SDK</h4>
                  <p>Web5 Nexus offers an easy-to-use decentralized database system based on OrbitDB, a serverless, distributed, peer-to-peer database. This feature enables developers to store and retrieve data in a decentralized manner, enhancing data security and resilience.</p>
                  <Button>Coming Soon</Button>
                </div>
              </div>
              <div className='col-lg-4' >
                <div className='uses_card' data-scroll="" data-scroll-speed="1" data-scroll-direction="top">
                  <div className='lable_sect'> <i class="ri-paint-fill"></i> Decentralized Storage  </div>
                  <h4>Decentralized Storage API & SDK</h4>
                  <p>With the Decentralized Storage API & SDK, developers can seamlessly integrate IPFS (InterPlanetary File System) and Filecoin into their applications. This allows for secure and distributed file storage, ensuring data availability and integrity in a decentralized manner</p>
                  <Button>Coming Soon</Button>
                </div>
              </div>
              <div className='col-lg-4' >
                <div className='uses_card' data-scroll="" data-scroll-speed="1" data-scroll-direction="top">
                  <div className='lable_sect'> <i class="ri-paint-fill"></i> Decentralized ID Verification  </div>
                  <h4>Decentralized ID Verification</h4>
                  <p>This feature provides an identity infrastructure SDK based on the Iden3 core protocol and 0xPolygon ID. It enables developers to implement decentralized identity verification, enhancing the security and privacy of user identities within their applications.</p>
                  <Button>Coming Soon</Button>
                </div>
              </div>
              <div className='col-lg-4' >
                <div className='uses_card' data-scroll="" data-scroll-speed="1" data-scroll-direction="top">
                  <div className='lable_sect'> <i class="ri-paint-fill"></i> Blockchain Development Kit </div>
                  <h4>Blockchain Bundle</h4>
                  <p>The Blockchain Bundle feature simplifies the creation of both public and private blockchains using Polygon edge and Quorum Core. Developers can leverage this functionality to set up customized blockchain networks tailored to their specific requirements.</p>
                  <Button>Coming Soon</Button>
                </div>
              </div>
              <div className='col-lg-4' >
                <div className='uses_card' data-scroll="" data-scroll-speed="1" data-scroll-direction="top">
                  <div className='lable_sect'> <i class="ri-paint-fill"></i> Block Explorer Kit  </div>
                  <h4>Block Explorer Bundle</h4>
                  <p>The Block Explorer Bundle allows developers to create block explorers for public and private blockchains using Blockscout Core. It provides users with a convenient way to explore and visualize blockchain transactions and data.</p>
                  <Button>Coming Soon</Button>
                </div>
              </div>
              <div className='col-lg-4' >
                <div className='uses_card' data-scroll="" data-scroll-speed="1" data-scroll-direction="top">
                  <div className='lable_sect'> <i class="ri-paint-fill"></i> Decentralized Payment Module  </div>
                  <h4>Decentralized Payment Gateway</h4>
                  <p>The Non Custodial Payment Gateway For Deposit & Withdrawal API and SDK enable developers to integrate decentralized payment gateways for custom tokens and coins. Make secure and transparent transactions within applications without relying on centralized intermediaries.</p>
                  <Button>Coming Soon</Button>
                </div>
              </div>


            </div>
          </div>
        </section>
        <section className='usecases sectio_pading bg_hight_sectio'>
          <div className='container'>
            <div className='contet_2 contet_3 title_center_flex'>
              <h2 className='text-center'>Supported<br />Blockchain Network</h2>
            </div>




            <div className='row mt-5 justify-content-center'>

              <div style={{ color: 'white' }} className="row mt-5 justify-content-center">
                {data.map((screenshot, index) => (
                  <div className="col-lg-1 col-md-2 col-sm-3">
                    <div className="finance-card shadow">
                      <div className="card-img-fiance">
                        <img src={`https://transfer.swft.pro/swft-v3/images/coins/${screenshot.chain}.png`} alt={screenshot.name} className="img-fluid" />
                      </div>

                    </div>
                    <div className="featuretext mt-3 justify-content-center">
                      <h5 style={{ textAlign: 'center' }} className="card-title">{screenshot.name}</h5>
                    </div>
                  </div>
                ))}
              </div>
              {/* <div className='col-lg-3'>
                <div className='logos_logo-wrapper'>
                  <img src={require('../img/xdc.png')} className='logos_logo xdc_lgo' alt='blockchain logo' />
                </div>
              </div>

              <div className='col-lg-3'>
                <div className='logos_logo-wrapper'>
                  <img src={require('../img/cosmos.png')} className='logos_logo' alt='blockchain logo' />
                </div>
              </div>
              <div className='col-lg-3'>
                <div className='logos_logo-wrapper'>
                  <img src={require('../img/coreum-logo.png')} className='logos_logo' alt='blockchain logo' />
                </div>
              </div>
              <div className='col-lg-3'>
                <div className='logos_logo-wrapper'>
                  <img src='https://assets.website-files.com/636258ae797b6765ebe1590e/636ae02c6e00cb2c2059c5df_ethereum_logo.svg' className='logos_logo' alt='blockchain logo' />
                </div>
              </div>
              <div className='col-lg-3'>
                <div className='logos_logo-wrapper'>
                  <img src='https://assets.website-files.com/636258ae797b6765ebe1590e/636ae038737cfcab28992b6b_polygon_logo.svg' className='logos_logo' alt='blockchain logo' />
                </div>
              </div>
              <div className='col-lg-3'>
                <div className='logos_logo-wrapper'>
                  <img src={require('../img/Binance.png')} className='logos_logo' alt='blockchain logo' />
                </div>
              </div>
              <div className='col-lg-3'>
                <div className='logos_logo-wrapper'>
                  <img src='https://assets.website-files.com/636258ae797b6765ebe1590e/636ae04c3004f317cc22c4fd_avalanche_logo.svg' className='logos_logo' alt='blockchain logo' />
                </div>
              </div>
              <div className='col-lg-3'>
                <div className='logos_logo-wrapper'>
                  <img src='https://assets.website-files.com/636258ae797b6765ebe1590e/636ae05460df0b79109f3ca9_hedera_logo.svg' className='logos_logo' alt='blockchain logo' />
                </div>
              </div>
              <div className='col-lg-3'>
                <div className='logos_logo-wrapper'>
                  <img src='https://assets.website-files.com/636258ae797b6765ebe1590e/636ae05be072780262233c88_immutable-x_logo.svg' className='logos_logo' alt='blockchain logo' />
                </div>
              </div> */}
            </div>

          </div>
        </section>
        <section id="solutions" className='usecases  pb-0 solutions_card section_final_s'>
          <div className='container'>
            <div className='contet_2 contet_3 title_center_flex'>
              <h2 className='text-center'>Start building Web3 with
                <br />Web5 Nexus</h2>
              <p className='text-center'>Web5 Nexus provides the best-in-class blockchain developer tools, resources and support to help you
                build and scale any Web3 product seamlessly.</p>
              <Button>Connect With Us</Button>
            </div>


          </div>
        </section>
        <footer>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-4'>
                <div className='text_footer'>
                  <div className='logo_section'>
                    <a class="navbar-brand light" href="https://web5.nexus/"><img src={require('../img/Web5logo.png')} alt='logo' className='logo' /></a>

                  </div>
                  <div className='socila_link'>
                    <ul>
                      {/* <li><a href='' rel={'noreferrer'} target='_blank'><i class="ri-facebook-fill"></i></a></li>
                      <li><a href='' rel={'noreferrer'} target='_blank'><i class="ri-twitter-fill"></i></a></li>
                      <li><a href='' rel={'noreferrer'} target='_blank'><i class="ri-telegram-fill"></i></a></li>
                      <li><a href='' rel={'noreferrer'} target='_blank'><i class="ri-whatsapp-fill"></i></a></li> */}

                    </ul>

                  </div>
                </div>
              </div>
              <div className='col-lg-8'>
                <div className='row'>
                  <div className='col-lg-4'>
                    <div className='link_date'>
                      <h6>Products</h6>
                      <ul>
                        <li><a href='https://docs.web5.nexus/'>Wallet</a></li>
                        <li><a href='https://docs.web5.nexus/'>Market</a></li>
                        <li><a href='https://docs.web5.nexus/'>NFT Tools</a></li>
                        <li><a href='https://docs.web5.nexus/'>Game toolkit</a></li>

                      </ul>
                    </div>
                  </div>
                  <div className='col-lg-4'>
                    <div className='link_date'>
                      <h6>Developers</h6>
                      <ul>
                        <li><a href='https://docs.web5.nexus/'>Documentation</a></li>
                        <li><a href='mailto:connect@web5.nexus'>Support</a></li>

                      </ul>
                    </div>
                  </div>

                  <div className='col-lg-4'>
                    <div className='link_date'>
                      <h6>Company</h6>
                      <ul>
                        <li><a href='#about'>About us</a></li>
                        {/* <li><a href=''>Careers</a></li>
                        <li><a href=''>Blog</a></li>
                        <li><a href=''>Podcast</a></li> */}

                      </ul>
                    </div>
                  </div>

                </div>
              </div>

            </div>
            <div className='row mt-5' >
              <div className='col-lg-12'>
                <ul className='nexcud'>
                  <li><a href='https://web5.nexus/'>Privacy Policy</a></li>
                  <li><a href='https://web5.nexus/'>Terms of Service</a></li>
                  <li><a href='https://web5.nexus/'>Cookies Policy</a></li>
                  <li><a href='https://web5.nexus/'>Security</a></li>

                </ul>
                <div className='copy_right'>
                  <p>© 2023 Web5 Nexus. All right reserved.</p>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </main>
    </>
  )
}

export default NewHome