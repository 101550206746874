import React, { useState } from "react";
import {
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom"; // Import Link for routing

const pages = [
  { text: "Home", link: "/" },
  { text: "Services", link: "/services" },
  {
    text: "Build",
    link: "",
    submenus: [
      { text: "SDK", link: "https://github.com/web5nexus" },
      { text: "Documentation", link: "https://docs.web5.nexus" },
      { text: "Dashboard", link: "https://dashboard.web5.nexus" },
    ],
  },
  {
    text: "Product",
    link: "",
    submenus: [
      { text: "Social Login", link: "https://www.npmjs.com/package/@web5nexus/sociallogin" },
      { text: "Cross DEX", link: "/crossdex" },
      { text: "On/Off ramp", link: "/onofframp" },
    ],
  },
  { text: "Solution", link: "" },
  { text: "Contact Us", link: "mailto:connect@web5.nexus" },
  { text: "Product Deck", link: "https://deck.web5.nexus/" },
];

const DrawerComp = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const drawerWidth = 240;

  return (
    <React.Fragment>
      <Drawer
        anchor="left"
        open={openDrawer}
        sx={{
          display: { xs: "block", sm: "block" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
        }}
        onClose={() => setOpenDrawer(false)}
      >
        <List>
          {pages.map((page, index) => (
            <React.Fragment key={index}>
              <ListItemButton component={Link} to={page.link}>
                <ListItemIcon>
                  <ListItemText>{page.text}</ListItemText>
                </ListItemIcon>
              </ListItemButton>
              {page.submenus && (
                <List sx={{ paddingLeft: 2 }}>
                  {page.submenus.map((submenu, subIndex) => (
                    <ListItemButton
                      key={subIndex}
                      component={Link}
                      to={submenu.link}
                    >
                      <ListItemIcon>
                        <ListItemText>{submenu.text}</ListItemText>
                      </ListItemIcon>
                    </ListItemButton>
                  ))}
                </List>
              )}
            </React.Fragment>
          ))}
        </List>
      </Drawer>
      <IconButton
        sx={{ color: "white", marginLeft: "auto" }}
        onClick={() => setOpenDrawer(!openDrawer)}
      >
        <MenuIcon color="white" />
      </IconButton>
    </React.Fragment>
  );
};

export default DrawerComp;
