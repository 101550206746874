import Button from '@mui/material/Button';
// import React, { useEffect, useRef } from 'react';

function Header() {
  return (
    <>
    <nav class="navbar navbar-expand-lg navbar-light bg-light header_new">
  <a class="navbar-brand dark" href="#mainhome"><img src={require('../img/Web5logo.png')} alt='logo' className='logo'/></a>
  <a class="navbar-brand light" href="#mainhome"><img src={require('../img/Web5logolight.png')} alt='logo' className='logo'/></a>

  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse scrolled navbar-collapse" id="navbarSupportedContent">
    
    <ul class="navbar-nav  mr-auto ">
    
      <li class="nav-item ">
        <a class="nav-link" href="#mainhome">Home <span class="sr-only">(current)</span></a>
      </li>
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#mainhome" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Build
        </a>
        <div class="dropdown-menu mega-menu" aria-labelledby="navbarDropdown">
          <div class="row">
            <div class="col-sm-12">
              <div className='section_menur'>
                <a href='https://github.com/web5nexus' rel={'noreferrer'} target={'_blank'}>
                  <div>
                  <i class="ri-tools-fill"></i>
                  </div>
                  <div className='sdk_list'>
                    <p>SDK</p>
                    <small>Learn about our powerful SDK module</small>
                    </div>
                </a>
                
                <a href='https://docs.web5.nexus' rel={'noreferrer'} target={'_blank'}>
                  <div>
                  <i class="ri-file-list-2-line"></i>
                  </div>
                  <div className='sdk_list'>
                    <p>Documentation</p>
                    <small>Read the official guides & start integrating today</small>
                    </div>
                </a>
                <a href='https://dashboard.web5.nexus'>
                  <div>
                  <i class="ri-dashboard-line"></i>
                  </div>
                  <div className='sdk_list'>
                    <p>Dashboard</p>
                    <small>Register your project & make it seamless</small>
                    </div>
                </a>
              </div>
            </div>
            
          </div>
        </div>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#products">Product</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#solutions">Solution</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="mailto:connect@web5.nexus">Contact us</a>
      </li>
      
      
    </ul>
    <div>
      <a href='https://deck.web5.nexus/' rel={'noreferrer'} target={'_blank'}><Button className='book_btn'>Product Deck</Button></a>
    </div>
  </div>
  
</nav>

</>
  )
}

export default Header