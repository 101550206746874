import { React, useEffect } from "react";
import "./App.css";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import NewHome from "./Pages/NewHome";
import Product from "./Pages/CrossDex";
import OnOfframp from "./Pages/OnOfframp";

import AOS from "aos";
import "aos/dist/aos.css";
function App() {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <BrowserRouter>
        <Routes>
          {/* <Route path="/new" element={<NewHome />} /> */}
          <Route path="/" element={<NewHome />} />
          <Route path="/crossdex" element={<Product />} />
          <Route path="/onofframp" element={<OnOfframp />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
