import React from "react";
import Header from "./HeaderNew";
import ButtonTrax from "./Buttontrak";
import SyntaxHighlighter from "react-syntax-highlighter";
import Button from "@mui/material/Button";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Token from "./Componant/Token";
import { dark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import data from "./blockchain.json"

function NewHome() {
  const settings = {
    dots: false,
    arrows: true, // Hide the dots navigation
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };
  const codeString = ` import Web5Nexus from "@web5nexus/web3auth-core";

    const clientId = "Your Web3Auth Client ID"
    
    // Define an async function to wrap the code
    async function runWeb5() {
      // Initialize Web5 Nexus
      const web5 = new Web5Nexus.Web5(clientId, "MAINNET");
      const blockchain = "xinfin";
      await web5.init(blockchain);
  
      // Call the Google Auth
      await web5.google();
    }
  
    // Call the async function
    runWeb5().catch((error) => {
      console.error("An error occurred:", error);
    });
    `;

  const codeString1 = ` import Web5Nexus, { Web3AuthNoModal } from "@web5nexus/web3auth-core";
  
    const clientId = "Your Web3Auth Client ID"
  
    function App() {
      const [web3auth, setWeb3auth] = useState<Web3AuthNoModal | null>(null);
      const [provider, setProvider] = useState<any | null>(null);
      
      // Initialize Web5
      const web5 = new Web5Nexus.Web5(clientId, "MAINNET");
  
      useEffect(() => {
  
        const initialize = async () => {
          try {
            const blockchain = "xinfin";
            await web5.init(blockchain);
          } catch (error) {
            console.error(error);
          }
        }
        initialize();
    
      }, []);
  
      const loginGoogle = async () => {
        const web3authProvider = await web5.google()
        setProvider(web3authProvider);
        setWeb3auth(web5.web3auth);
      };
  
      return (
        // Your UI Here
      )
    }
    export default App;`;

  const codeString2 = ` import { Web5 } from "@web5nexus/web3auth-core";
  
    const clientId: string = "Your Web3Auth Client ID";
    
    // Define an async function to wrap the code
    async function runWeb5(): Promise<void> {
      // Initialize Web5 Nexus
      const web5: Web5 = new Web5(clientId, "MAINNET");
      const blockchain: string = "xinfin";
      await web5.init(blockchain);
    
      // Call the Google Auth
      await web5.google();
    }
    
    // Call the async function
    runWeb5().catch((error: Error) => {
      console.error("An error occurred:", error);
    });`;

  return (
    <>
      <Header />
      <main className="main_padding">
        <section className="banner_new new_product_banner new_product_banner_offramp">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="product_banner ">
                  <h1 className="text-center">
                    Make <span>Cryptocurrency</span> Buying and Trading a Breeze
                    in Your App!
                  </h1>
                  <p className="text-center">
                    Provide millions of users worldwide with a seamless link
                    between cryptocurrency and traditional currency, and
                    increase your earnings with a straightforward, cost-free
                    integration.
                  </p>
                  <div className="button_section d-flex justify-content-center">
                    <a
                      href="https://crossdex.web5.nexus/#/"
                      target="_blank"
                      className="outline"
                    >
                      Explore
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section_2  section_4 section__produce typrs_of_ramps">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="contet_3_stici ">
                  <div className="contet_2 contet_3">
                    <span>
                      Streamlining the Cryptocurrency Onboarding Process.
                    </span>
                    <h2>
                      Unlock Two Potent Ramps with a Single,{" "}
                      <span>No-Cost</span> Integration.
                    </h2>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="secure_content">
                  <div className="card_secure d-flex">
                    <div className="img_secur">
                      <img
                        src={require("../img/offramp/cryptocurrency.png")}
                        className="img-fluid"
                      />
                    </div>
                    <div>
                      <h3>Fiat to crypto on-ramp</h3>
                      <p>
                        Effortlessly welcome new users with our comprehensive,
                        non-custodial on-ramp.
                      </p>
                    </div>
                  </div>
                  <div className="card_secure d-flex">
                    <div className="img_secur">
                      <img
                        src={require("../img/offramp/save-money.png")}
                        className="img-fluid"
                      />
                    </div>
                    <div>
                      <h3>Crypto to fiat off-ramp</h3>
                      <p>
                        Enable users with the ability to easily convert back to
                        traditional currency in just a few clicks using our
                        truly global off-ramp.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section_2  section_4 section__produce bg_final welcoming_crytpo">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="contet_2 contet_3 brinde_nexsu">
                  <span>Boosting Your Business's Growth </span>
                  <h2>
                    A <span>Welcoming</span>, Comprehensive Crypto Toolkit
                    <br />
                    Tailored for Businesses
                  </h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4">
                <div className="card_secure">
                  <div className="img_secur">
                    <img
                      src={require("../img/offramp/revenue.png")}
                      className="img-fluid"
                    />
                  </div>
                  <div>
                    <h3>New revenue streams</h3>
                    <p>Unlock new sources of revenue for your business.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="card_secure">
                  <div className="img_secur">
                    <img
                      src={require("../img/offramp/bolt.png")}
                      className="img-fluid"
                    />
                  </div>
                  <div>
                    <h3>Lightning-fast transactions</h3>
                    <p>
                      Increase conversions with the fastest crypto fiat
                      deliveries on the market.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="card_secure">
                  <div className="img_secur">
                    <img
                      src={require("../img/offramp/pattern.png")}
                      className="img-fluid"
                    />
                  </div>
                  <div>
                    <h3>Seamless flow</h3>
                    <p>
                      Familiar e-commerce-like experience that convert beyond
                      crypto natives.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="card_secure">
                  <div className="img_secur">
                    <img
                      src={require("../img/offramp/customer-service.png")}
                      className="img-fluid"
                    />
                  </div>
                  <div>
                    <h3>Outstanding support</h3>
                    <p>
                      Dedicated partnerships team and partner portal. 24/7
                      support for end-users.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="card_secure">
                  <div className="img_secur">
                    <img
                      src={require("../img/offramp/compliant.png")}
                      className="img-fluid"
                    />
                  </div>
                  <div>
                    <h3>Built-in compliance</h3>
                    <p>
                      We handle KYC, AML, and regulations on all transactions
                      coming through our widget.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="card_secure">
                  <div className="img_secur">
                    <img
                      src={require("../img/offramp/encrypted.png")}
                      className="img-fluid"
                    />
                  </div>
                  <div>
                    <h3>Security by design</h3>
                    <p>
                      Industry-leading security, fraud prevention, and full
                      chargeback handling.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section_2 section_3 Section_secure_card benjfit_section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 ">
                <div className="contet_2 contet_3">
                  <span>Who Can Make the Most of This?</span>
                  <h2 className="secodary_h2">
                    Support for all businesses, crypto <br /> native or not
                  </h2>
                  <p>
                    Highly customizable experience to match your brand without
                    <br />
                    breaking your user experience.
                  </p>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-6 d-flex">
                <div className="card_secure">
                  <div>
                    <h3>Web2 enterprises</h3>
                    <p>
                      We provide you with the crypto onboarding toolkit you need
                      to start tapping into a growing web3 market.
                    </p>
                  </div>
                  <div className="img_secur">
                    <img
                      src={require("../img/offramp/enterprise.png")}
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 d-flex">
                <div className="card_secure">
                  <div>
                    <h3>Crypto wallets</h3>
                    <p>
                      We connect crypto wallets with the global financial system
                      so your users can move seamlessly between fiat and crypto.
                    </p>
                  </div>
                  <div className="img_secur">
                    <img
                      src={require("../img/offramp/crypto.png")}
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 d-flex">
                <div className="card_secure">
                  <div>
                    <h3>Web3 games</h3>
                    <p>
                      We empower your play-to-earn game to realize your full
                      economic potential without breaking the gaming experience.
                    </p>
                  </div>
                  <div className="img_secur">
                    <img
                      src={require("../img/offramp/joystick.png")}
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 d-flex">
                <div className="card_secure">
                  <div>
                    <h3>Defi platforms</h3>
                    <p>
                      We help your DeFi project put your financial innovation
                      front and center, while we handle the more mundane “fiat
                      payments” part.
                    </p>
                  </div>
                  <div className="img_secur">
                    <img
                      src={require("../img/offramp/market.png")}
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 d-flex">
                <div className="card_secure">
                  <div>
                    <h3>NFT marketplaces</h3>
                    <p>
                      We allow NFT Marketplaces to focus on the experience of
                      actually creating and trading NFTs, instead of how to buy
                      crypto to pay for them.
                    </p>
                  </div>
                  <div className="img_secur">
                    <img
                      src={require("../img/offramp/marketplace.png")}
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="CTA code_developmant">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <div className="CTAcontent">
                  <div className="headiingm">
                    <span>Developers, You're Our Favorites!</span>
                  </div>
                  <h2>Only a handful of lines of code</h2>
                  <p>
                    Your development team can establish your on-ramp and
                    off-ramp within minutes, either with the assistance of our
                    in-house dedicated partner team or by utilizing our APIs for
                    a more seamless integration.
                  </p>
                  <a href="mailto:connect@web5.nexus">
                    <Button>Documentation</Button>
                  </a>
                </div>
              </div>
              {/* <div className="col-lg-6">
                <div className="code_section">
                  <SyntaxHighlighter
                    language="javascript"
                    style={dark}
                    customStyle={{ padding: "25px" }}
                    wrapLongLines={true}
                  >
                    {codeString}
                  </SyntaxHighlighter>
                </div>
              </div> */}
            </div>
          </div>
        </section>
        <section className="product_new_sectoion new_web_site">
          <div className="container">
            <div className="card_product_diplay">
              <div className="row">
                <div className="col-lg-12 mb-4">
                  <div className="contet_2 contet_3 align-items-center">
                    <h2 className="text-center secodary_h2">
                      Discover the exceptional quality of our <br />
                      <span className="secodary_span">on-ramp/off-ramp </span>
                      solutions with
                      <br /> a friendly touch.
                    </h2>

                    <p className="text-center">
                      The Safest and Most Dependable On-Ramp/Off-Ramp Solutions
                      – Making Your Trades Easier
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 d-flex justify-content-center">
                  <div class="iphone-x">
                    <div class="side">
                      <div class="screen">
                        <iframe
                          src="https://swipelux.web5.nexus/"
                          width="100%"
                          height="100%"
                        />
                      </div>
                    </div>
                    <div class="line"></div>
                    <div class="header">
                      <div class="sensor-1"></div>
                      <div class="sensor-2"></div>
                      <div class="sensor-3"></div>
                    </div>
                    <div class="volume-button"></div>
                    <div class="power-button"></div>
                  </div>
                </div>
                <div className="col-lg-6 d-flex justify-content-center">
                  <div class="iphone-x">
                    <div class="side">
                      <div class="screen">
                        <iframe
                          src="https://onmeta.web5.nexus/"
                          width="100%"
                          height="100%"
                        />
                      </div>
                    </div>
                    <div class="line"></div>
                    <div class="header">
                      <div class="sensor-1"></div>
                      <div class="sensor-2"></div>
                      <div class="sensor-3"></div>
                    </div>
                    <div class="volume-button"></div>
                    <div class="power-button"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="usecases sectio_pading bg_hight_sectio product_support">
          <div className="container">
            <div className="contet_2 contet_3 title_center_flex">
              <h2 className="text-center secodary_h2">
                <span className="secodary_span">Supported</span>
                <br />
                Blockchain Network
              </h2>
            </div>
            <div className="row mt-5 justify-content-center">
              <div style={{ color: 'white' }} className="row mt-5 justify-content-center">
                {data.map((screenshot, index) => (
                  <div className="col-lg-1 col-md-2 col-sm-3">
                    <div className="finance-card shadow">
                      <div className="card-img-fiance">
                        <img src={`https://transfer.swft.pro/swft-v3/images/coins/${screenshot.chain}.png`} alt={screenshot.name} className="img-fluid" />
                      </div>

                    </div>
                    <div className="featuretext mt-3 justify-content-center">
                      <h5 style={{ textAlign: 'center' }} className="card-title">{screenshot.name}</h5>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
        
        <section className="usecases sectio_pading bg_hight_sectio product_support">
          <div className="container">
            <div className="contet_2 contet_3 title_center_flex font-size40">
              <h2 className="text-center secodary_h2">
                <span className="secodary_span">Supported</span>
                <br />
                Tokens
              </h2>
            </div>

            <div className="height_contain">
              <Token />
            </div>
          </div>
        </section>

        <section className=" mb-5 ">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 ">
                <div className="CTA CTA_card_section">
                  <h2>Let’s get your integration started</h2>
                  <a href="">Contact us</a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <footer>
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="text_footer">
                  <div className="logo_section">
                    <a class="navbar-brand light" href="https://web5.nexus/">
                      <img
                        src={require("../img/Web5logo.png")}
                        alt="logo"
                        className="logo"
                      />
                    </a>
                  </div>
                  <div className="socila_link">
                    <ul>
                      {/* <li><a href='' rel={'noreferrer'} target='_blank'><i class="ri-facebook-fill"></i></a></li>
                      <li><a href='' rel={'noreferrer'} target='_blank'><i class="ri-twitter-fill"></i></a></li>
                      <li><a href='' rel={'noreferrer'} target='_blank'><i class="ri-telegram-fill"></i></a></li>
                      <li><a href='' rel={'noreferrer'} target='_blank'><i class="ri-whatsapp-fill"></i></a></li> */}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="row">
                  <div className="col-lg-4">
                    <div className="link_date">
                      <h6>Products</h6>
                      <ul>
                        <li>
                          <a href="https://docs.web5.nexus/">Wallet</a>
                        </li>
                        <li>
                          <a href="https://docs.web5.nexus/">Market</a>
                        </li>
                        <li>
                          <a href="https://docs.web5.nexus/">NFT Tools</a>
                        </li>
                        <li>
                          <a href="https://docs.web5.nexus/">Game toolkit</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="link_date">
                      <h6>Developers</h6>
                      <ul>
                        <li>
                          <a href="https://docs.web5.nexus/">Documentation</a>
                        </li>
                        <li>
                          <a href="mailto:connect@web5.nexus">Support</a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="col-lg-4">
                    <div className="link_date">
                      <h6>Company</h6>
                      <ul>
                        <li>
                          <a href="#about">About us</a>
                        </li>
                        {/* <li><a href=''>Careers</a></li>
                        <li><a href=''>Blog</a></li>
                        <li><a href=''>Podcast</a></li> */}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-lg-12">
                <ul className="nexcud">
                  <li>
                    <a href="https://web5.nexus/">Privacy Policy</a>
                  </li>
                  <li>
                    <a href="https://web5.nexus/">Terms of Service</a>
                  </li>
                  <li>
                    <a href="https://web5.nexus/">Cookies Policy</a>
                  </li>
                  <li>
                    <a href="https://web5.nexus/">Security</a>
                  </li>
                </ul>
                <div className="copy_right">
                  <p>© 2023 Web5 Nexus. All right reserved.</p>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </main>
    </>
  );
}

export default NewHome;
